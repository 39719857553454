
export const experienceData =
[
    {
        "id":0,
        "company": "Pebble Technologies",
        "date": "March2022 - Present",
        "position": "Junior Software Developer",
        "roles":[
            "Developed a mobile based casino style video game from scratch using the Unity Game Engiene",
            "Implimented a CRUD application to fetch and push to a Restful API.",
            "Learned about writting industry standard code which is redable and scalable.",
            "Worked with C# and Unity.",
        ]
    },
    {
        "id":1,
        "company": "Data Research Council For Students",
        "date": "Nov2022 - Present",
        "position": "Graphic Designer",
        "roles":[
            "Designed The DRCFS website",
            "Designed and developed social media posts",
            "Helped the communities social media page grow",
            "Built a startup from scratch"
        ]
    }
   
]